import { useEffect, useState } from 'react';
import { usePushAlert } from '@noah-labs/fe-shared-ui-components';
import { logger } from '@noah-labs/shared-logger/browser';
import { getErrorMessage, getPropertyByPath } from '@noah-labs/shared-util-vanilla';
import {
  AlertAuthUnknown,
  AlertMessage,
  AlertSignUpEmailAlreadyExists,
} from '../components/alerts/Alerts';

export type TpUseAuthError = {
  AuthErrorScene: React.ReactElement | null;
};
export type PpUseAuthError = {
  error: unknown;
};
export function useAuthError({ error }: PpUseAuthError): TpUseAuthError {
  const [authErrorScene, setAuthErrorScene] = useState<React.ReactElement | null>(null);
  const pushAlert = usePushAlert();

  useEffect(() => {
    if (!error) {
      setAuthErrorScene(null);
      return;
    }
    logger.error(error);
    /**
     * Auth Error handler
     */
    const errMsg = getErrorMessage(error);
    const errorType = getPropertyByPath(error, ['type']);

    switch (errorType) {
      case 'IdentifierExists':
        pushAlert(AlertSignUpEmailAlreadyExists);
        return;
      case 'Unspecified':
      case 'InvalidCredentials':
        pushAlert(AlertMessage(errMsg));
        return;
      default:
        // Do nothing
        break;
    }

    /**
     * Seems to be an unknown error message, display default generic message
     */
    pushAlert(AlertAuthUnknown);
    setAuthErrorScene(null);
  }, [error, pushAlert]);

  return { AuthErrorScene: authErrorScene };
}
